/**
 * 打开新的webview
 * @param link
 * @param params
 * @param app
 * @returns
 */
import appEnv from "../utils/env";

const Env = appEnv;
const WEBVIEW_PATH = "app://page/webRouter?"; // webView 协议 path
function qsStringify(params: { [key: string]: any }) {
  const arr = [];
  for (const key of Object.keys(params)) {
    const value = params[key];
    arr.push(`${key}=${encodeURIComponent(value)}`);
  }
  return arr.join("&");
}
export function getQueryParams(data: { [prop: string]: any }) {
  const strArr: string[] = [];
  Object.keys(data).forEach((item) => {
    strArr.push(`${item}=${data[item]}`);
  });
  return strArr.join("&");
}
interface WebViewParams {
  url?: string;
  title?: string;
  native?: number;
  meta?: any;
}
export function createWebViewLink(
  link: string,
  params: WebViewParams = {},
  app?: null
) {
  if (link.indexOf("com") === 0) {
    return link;
  }
  if (link) {
    params.url = link;
  }
  if (Env.isAppWebView || app) {
    const extraParams = getQueryParams(params.meta);
    delete params.meta;
    const query = qsStringify(params);
    return `${WEBVIEW_PATH}${query}&${extraParams}`;
  } else {
    return link;
  }
}
